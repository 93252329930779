import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639668475/280804_1_lgqxmd.jpg",
        "alt": "Selva-trágica_tomatazos.com"
      }}></img></p>
    <p>{`El Caribe es una zona geográfica delimitada por el mar que lleva el mismo nombre: desde República Dominicana y Cuba, pasando por la península mexicana y América Central, hasta llegar al norte de Colombia, Venezuela y todas las islas junto a Puerto Rico. Y aunque lo primero que pensamos cuando hablamos de esta zona es sol, arena y mar, existen otros paisajes tan llenos de vida, que son capaces de convertirse en los protagonistas de la historia.`}</p>
    <p>{`Tal es el caso de la película que toca reseñar en este número: `}<em parentName="p">{`Selva trágica`}</em>{` (2020). Una producción escrita y dirigida por la mexicana Yulene Olaizola, que llegó a Netflix desde junio del año en curso, después de cosechar importantes galardones en ceremonias tan conocidas como el Festival Internacional de Cine de Venecia. Además, es una de las películas más nominadas en la edición más reciente de premios Ariel, en México.`}</p>
    <p>{`Dicho lo anterior, comencemos a relatar de qué va esta historia. La película nos narra la travesía de una joven que durante los años veinte se interna en la selva que rodea el Río Hondo, una frontera natural entre México y Belice que desemboca en el Mar Caribe. Ella está huyendo de un matrimonio forzado con un británico que la busca e intentará matarla si la encuentra.`}</p>
    <p>{`En su camino es apoyada por otro hombre y una mujer que aparentemente es enfermera, pero mueren en los primeros minutos de la película. Esto obliga a nuestra protagonista a cruzar al lado mexicano del río, pero antes, decide cambiar ropa con su compañera muerta, para lograr despistar a quien la persigue.`}</p>
    <p>{`Más adelante es encontrada por un grupo de chicleros que decide capturarla para que cure a uno de ellos. Estos hombres se dedican a extraer la resina del árbol de chicozapote para elaborar goma de mascar. Un negocio en el que se explotaban recursos naturales en ambos lados de la frontera y, de hecho, se encuentran en conflicto por las zonas en las que se puede obtener este material.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639668405/selvatragicareview_kozbye.jpg",
        "alt": "Selva-trágica_cineramaonline"
      }}></img></p>
    <p>{`Desde ese punto en adelante parece que la historia se detiene, pero lo que en realidad sucede es que, poco a poco, los hombres comienzan a descender en una especie de locura, que los “obliga” a sucumbir ante sus instintos más primarios y violentos. Una narrativa muy característica de los relatos sobre el encuentro de lo humano con lo salvaje de la naturaleza.`}</p>
    <p>{`Por ejemplo, una noche, embriagado de alcohol, machismo y poder, el líder del grupo aparta a la protagonista de los demás, la lleva por la fuerza al interior de la selva y la viola. A partir de ese momento, varios hombres comienzan a ver a la joven como una presa, sin embargo, parece que ese “deseo incontrolable” es la razón de sus muertes.`}</p>
    <p>{`Desde que la película inicia, uno de los chicleros relata, en voz en off, algunos fragmentos escritos por Antonio Méndez Bolio en uno de sus libros más reconocidos: `}<em parentName="p">{`La tierra del faisán y del venado`}</em>{` (1922). Esos versos cuentan la leyenda de Xtabay, una mujer hermosa y vestida de blanco que se aparece a los hombres arrogantes que vagan solos por la noche. Ellos la siguen, pero nunca vuelven.`}</p>
    <p>{`Así, la directora parece insinuar que nuestra protagonista es la Xtabay de este grupo de hombres. Y es su encanto el que “provoca” que ellos actúen de esa forma. Una vez que caen en su hechizo, o “sucumben” ante su deseo, mueren. Sin embargo, al observar con más detalle, es posible identificar en la trama ligeros matices que cambian esta perspectiva, respecto a la protagonista y su relación con la leyenda maya.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639668405/selva-tragica-2_svvg4o.jpg",
        "alt": "Selva-trágica_imcine.gob.mx"
      }}></img></p>
    <p>{`Volviendo a la película, hay una escena después de la muerte de la chica que acompaña a la protagonista, en la que uno de los hombres del británico intenta identificar el cuerpo, pero no logra hacerlo porque un cocodrilo está acechando. Cuando el hombre se retira, el cocodrilo regresa al río sin hacer nada con el cuerpo, como si sólo lo hubiera estado cuidando.`}</p>
    <p>{`En otro momento de la historia, cuando recién capturan a la protagonista, uno de los chicleros que más fascinado está por ella, se interna en la selva para extraer la resina del árbol del chicle y en una distracción, que parece provocada por los ruidos de la selva, el hombre cae y muere.`}</p>
    <p>{`Por último, está una escena donde la protagonista se oculta en la selva junto al hombre que la estuvo protegiendo de los demás, para tener relaciones sexuales consensuadas. Esto lo descubre el líder del grupo, que unos días antes la había violado. A la mañana siguiente, el hombre que estuvo con ella aparece muerto, sin embargo, el énfasis a las marcas de su cuello nos indica que no murió por causas sobrenaturales, sino porque alguien más lo asfixio, probablemente el violador que los observaba en la oscuridad.`}</p>
    <p>{`En conclusión, estos elementos, aunque parecen sólo un hecho más de la trama, marcan la diferencia que hay respecto al tropo de la selva malvada que convierte a la civilización en barbarie. Y también separa la leyenda del relato moralista de Méndez Bolio sobre una mujer que mata hombres a través del deseo.`}</p>
    <p><strong parentName="p">{`La directora nos propone una lectura contemporánea de esta historia que transcurre hace 100 años. No es nuestra Xtabay ni la selva quienes asesinan hombres, son ellos mismos bajo el pensamiento machista y colonialista de la época, que explotan recursos naturales, así como disponen de los cuerpos de las mujeres a su antojo. Ya sea para forzarlas a un matrimonio o para agredirlas sexualmente.`}</strong></p>
    <p>{`En este sentido, la selva y Xtabay se protegen y se defienden de esa violencia. El hombre que fascinado acosaba a la protagonista, cae de un árbol mientras intenta cortarlo para extraer resina. El violador muere en un enfrentamiento con otros chicleros, producto del colonialismo que ejerce su poder sobre los recursos de la selva. Y finalmente, el hombre que aparece muerto, después de tener relaciones con la protagonista, es asesinado por otro hombre en un arranque de celos.`}</p>
    <p>{`Además, no podemos olvidar la escena que describí hace un momento: `}<strong parentName="p">{`un cocodrilo, parece resguardar el cuerpo de una joven asesinada en manos de hombres que invaden y explotan todo espacio que no les pertenece. El espacio en el que la selva vive y el cuerpo que las mujeres habitan.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      